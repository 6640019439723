<template>
  <div>
    <b-modal
      id="modal-add-company"
      ref="modalAddCompany"
      hide-footer
      centered
      size="lg"
      title="Bedrijf toevoegen"
      :no-close-on-backdrop="false"
    >
      <b-card-text>
        <template #title>
          <feather-icon icon="UserPlusIcon"/>
          <span>Bedrijf aanmaken</span>
        </template>

        <b-card-text>
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormSearchRelationObserver"
          >
            <b-form
              @submit.prevent="handleSubmit(createCompany)"
            >
              <h6 class="card-title mt-1">Bedrijfsinformatie</h6>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Bedrijf"
                    label-for="company"
                  >
                    <vue-autosuggest
                      ref="autocomplete"
                      v-model="query"
                      :suggestions="suggestions"
                      :input-props="inputProps"
                      @selected="onSelected($event.item)"
                      :get-suggestion-value="getSuggestionValue"
                      @input="fetchResults"
                    >
                      <template slot-scope="{suggestion}">
                        <b-row>
                          <b-col
                            cols="12"
                          >
                            <span class="my-suggestion-item">{{
                                `${suggestion.item.companyName} - ${suggestion.item.cocNumber}`
                              }}</span>
                          </b-col>
                          <b-col
                            cols="12"
                          >
                            <span class="my-suggestion-item">{{
                                `${suggestion.item.street} ${suggestion.item.houseNumber}${suggestion.item.houseNumberAddition}, ${suggestion.item.postalCode} ${suggestion.item.city}`
                              }}</span>
                          </b-col>
                        </b-row>
                      </template>
                    </vue-autosuggest>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Bedrijfsnaam"
                    rules="required"
                  >
                    <b-form-group
                      label="Bedrijfsnaam"
                      label-for="companyName"
                    >
                      <b-form-input
                        id="companyName"
                        v-model="companyData.companyName"
                        disabled="disabled"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="KvK-nr"
                    rules="required"
                  >
                    <b-form-group
                      label="KvK-nr"
                      label-for="cocNumber"
                    >
                      <b-form-input
                        id="cocNumber"
                        v-model="companyData.cocNumber"
                        disabled="disabled"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Straat"
                    rules="required"
                  >
                    <b-form-group
                      label="Straat"
                      label-for="street"
                    >
                      <b-form-input
                        id="street"
                        v-model="companyData.street"
                        disabled="disabled"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Huisnummer"
                    rules="required"
                  >
                    <b-form-group
                      label="Huisnummer"
                      label-for="houseNumber"
                    >
                      <b-form-input
                        id="houseNumber"
                        v-model="companyData.houseNumber"
                        disabled="disabled"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Postcode"
                    rules="required"
                  >
                    <b-form-group
                      label="Postcode"
                      label-for="postalCode"
                    >
                      <b-form-input
                        id="postalCode"
                        v-model="companyData.postalCode"
                        disabled="disabled"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Plaats"
                    rules="required"
                  >
                    <b-form-group
                      label="Plaats"
                      label-for="city"
                    >
                      <b-form-input
                        id="city"
                        v-model="companyData.city"
                        disabled="disabled"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <h6 class="card-title mt-1">Contactpersoon</h6>
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Voornaam"
                    rules="required"
                  >
                    <b-form-group
                      label="Voornaam"
                      label-for="firstName"
                    >
                      <b-form-input
                        id="firstName"
                        v-model="companyData.firstName"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Achternaam"
                    rules="required"
                  >
                    <b-form-group
                      label="Achternaam"
                      label-for="lastName"
                    >
                      <b-form-input
                        id="lastName"
                        v-model="companyData.lastName"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="E-mailadres"
                    rules="required"
                  >
                    <b-form-group
                      label="E-mailadres"
                      label-for="email"
                    >
                      <b-form-input
                        id="email"
                        v-model="companyData.email"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Telefoon"
                    rules="required|integer"
                  >
                    <b-form-group
                      label="Telefoon"
                      label-for="phone"
                    >
                      <b-form-input
                        id="phone"
                        v-model="companyData.phone"
                        :state="getValidationState(validationContext)"
                        type="text"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <h6 class="card-title mt-1">Overige informatie</h6>
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="BTW-Nummer"
                    rules="regex:^(NL)?[0-9]{9}B[0-9]{2}$"
                  >
                    <b-form-group
                      label="BTW-Nummer"
                      label-for="vatNumber"
                    >
                      <b-form-input
                        id="vatNumber"
                        v-model="companyData.vatNumber"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Betaaltermijn"
                    rules="required"
                  >
                    <b-form-group
                      label="Betaaltermijn"
                      label-for="paymentTermDays"
                    >
                      <b-form-input
                        id="paymentTermDays"
                        v-model="companyData.paymentTermDays"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12 text-right mt-1 mb-2">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    type="submit"
                    :disabled="showAddCompanySpinner"
                  >
                    <b-spinner
                      small
                      class="mr-1"
                      v-if="showAddCompanySpinner"
                    />
                    <span>Toevoegen</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-text>
      </b-card-text>
    </b-modal>
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <b-overlay
          :show="showOverlay"
          rounded="sm"
        >
          <b-card
            no-body
          >
            <b-card-header>
              <b-card-title class="w-100">Bedrijven</b-card-title>
              <b-card-text class="font-small-2 mr-25 mb-0 w-100">
                Een overzicht van uw opdrachtgevers.
              </b-card-text>
            </b-card-header>
            <div class="m-2">
              <!-- Table Top -->
              <b-row>
                <!-- Per Page -->
                <b-col
                  cols="12"
                  md="6"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                  <label>Toon</label>
                  <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="perPageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
                  />
                  <label>items</label>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <div class="d-flex align-items-center justify-content-end">
                    <b-form-input
                      v-model="searchQuery"
                      class="d-inline-block mr-1"
                      placeholder="Zoeken op naam of email..."
                    />
                    <b-button
                      variant="primary"
                      @click="openModalCreateCompany"
                    >
                      <span class="text-nowrap">Toevoegen</span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
            <b-table
              ref="refCompaniesListTable"
              class="position-relative"
              :items="fetchCompanies"
              responsive
              :fields="tableColumns"
              primary-key="id"
              show-empty
              empty-text="No matching records found"
              :sort-by.sync="sortBy"
              :sort-desc.sync="isSortDirDesc"
            >
              <!-- Column: Id -->
              <template #cell(id)="data">
                #{{ data.value }}
              </template>

              <template #cell(contactperson)="data">
                {{ getFullName(data.item) }}
              </template>

              <template #cell(openAmount)="data">
                &euro; {{ data.value?parseFloat(data.value).toFixed(2):'0.00' }}
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-link
                  :to="{ name: 'app-company-edit', params: { id: data.item.id }}"
                  :id="`company-view-action-row-${data.item.id}`"
                >
                  <feather-icon
                    :id="`company-row-${data.item.id}-edit-icon`"
                    class="cursor-pointer mx-1"
                    icon="EyeIcon"
                    size="16"
                  />
                </b-link>
              </template>
            </b-table>
            <div class="mx-2 mb-2">
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{
                      dataMeta.of
                    }} entries</span>
                </b-col>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="total"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BLink,
  BOverlay,
  BPagination,
  BRow,
  BTable,
  VBToggle,
} from 'bootstrap-vue'
import { getFullName } from '@core/utils/utils'
import store from '@/store'
import { required, integer, regex } from '@validations'
import { onUnmounted } from '@vue/composition-api'
import useCompaniesList from '@/views/pages/companies/useCompaniesList'
import companiesStoreModule from '@/views/pages/companies/companiesStoreModule'
import vSelect from 'vue-select'
import { VueAutosuggest } from 'vue-autosuggest'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BOverlay,
    BFormInput,
    BCardHeader,
    BCardTitle,
    BCardText,
    BButton,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BLink,
    vSelect,
    VueAutosuggest,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  data() {
    return {
      companyData: {},
      inputProps: {
        id: 'autosuggest__input_ajax',
        class: 'form-control',
        placeholder: 'Zoek bedrijf in KvK register',
      },
      limit: 10,
      query: '',
      results: [],
      timeout: null,
      debounceMilliseconds: 250,
      suggestions: [],
      showAddCompanySpinner: false,
    }
  },
  methods: {
    fetchResults() {
      const { query } = this

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        store
          .dispatch('app-companies/searchCocRegister', {
            q: query,
            sortBy: 'name',
          })
          .then(response => {
            this.suggestions = [{
              data: response.data,
            }]
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Kvk register',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'Er is een fout opgetreden bij het opslaan van de data. Probeer later het nog een keer.',
              },
            })
          })
      }, this.debounceMilliseconds)
    },
    openModalCreateCompany() {
      this.$refs.modalAddCompany.show()
    },
    onSelected(value) {
      this.companyData = value
      this.companyData.name = value.companyName
    },
    getSuggestionValue(suggestion) {
      const { item } = suggestion
      return item.companyName
    },
    createCompany() {
      this.showAddCompanySpinner = true
      store.dispatch('app-companies/addCompany', this.companyData)
        .then(response => {
          if (response.data.emailSent !== null && response.data.emailSent === 1) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Bedrijf is aangemaakt',
                icon: 'EditIcon',
                variant: 'succes',
                text: 'De opdrachtgever heeft een mail ontvangen met instructies om zijn account aan te maken.',
              },
            })
          }
          this.showAddCompanySpinner = false
          this.$refs.modalAddCompany.hide()
          this.refetchData()
        })
        .catch(() => {
          this.showAddCompanySpinner = false
          this.$refs.modalAddCompany.hide()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Aanmaken bedrijf',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het opslaan van de data. Probeer later het nog een keer.',
            },
          })
        })
    },
  },
  setup() {
    const COMPANIES_APP_STORE_MODULE_NAME = 'app-companies'

    // Register module
    if (!store.hasModule(COMPANIES_APP_STORE_MODULE_NAME)) store.registerModule(COMPANIES_APP_STORE_MODULE_NAME, companiesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COMPANIES_APP_STORE_MODULE_NAME)) store.unregisterModule(COMPANIES_APP_STORE_MODULE_NAME)
    })

    const {
      getValidationState,
    } = formValidation()

    const {
      tableColumns,
      perPage,
      total,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      showOverlay,
      searchQuery,
      dataMeta,
      refCompaniesListTable,
      currentPage,
      fetchCompanies,
      refetchData,
    } = useCompaniesList()

    return {
      tableColumns,
      perPage,
      total,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      showOverlay,
      searchQuery,
      dataMeta,
      refCompaniesListTable,
      currentPage,
      fetchCompanies,
      refetchData,
      getValidationState,
      getFullName,
      required,
      integer,
      regex,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
